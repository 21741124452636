import React from "react"
import Layout from "../../components/member/Layout"
import Shop from "../../components/member/Shop"

export default () => {
  const pageSlug = "shop"
  const pageTitle = "ショップ"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Shop />
    </Layout>
  )
}
